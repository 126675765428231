




















































































































import { debounce } from '@bcase/core';
import { Research, ForEachTarget, Target } from '@app/models';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ResearchModule } from '../store/modules/research-module';
import { nl2br } from '../utils/simple-functions';
import { company } from '../store/modules/company-module';
import { ModuleModule } from '../store/modules/module-module';

@Component
export default class ResearchView extends Vue {
  public research = getModule(ResearchModule);
  public module = getModule(ModuleModule);
  public edit: Research.Mutable = Research.empty('', '');
  public editRespondent = false;

  public language: string = '';
  private initialized = false;
  private debouncedSave!: Function;

  public get routes() {
    return this.$customerNameShort === 'fb'
      ? ([
          'research-details-a',
          'research-details-b',
          'research-structure',
          'research-invitation',
          'research-introduction',
          'research-reminder',
          this.edit.structure && this.edit.structure.fsq && 'research-formula',
          'research-respondents',
          'research-summary',
        ].filter(Boolean) as string[])
      : [
          'research-details-a',
          'research-details-b',
          'research-details-c',
          'research-example',
          'research-invitation',
          'research-introduction',
          'research-reminder',
          'research-respondents',
          'research-summary',
        ];
  }

  public get steps() {
    const current = this.routes.indexOf(this.$route.name || '');

    return this.routes.map((route, i) => ({
      active: i <= current,
      disabled: i > this.edit.step,
      label: 'step-' + route,
      name: route,
    }));
  }

  public get id() {
    return this.$route.params.id;
  }

  public get respondents() {
    return this.research.respondents.filter(r => r.rid === this.id);
  }

  public get currentRoute() {
    const index = this.routes.findIndex(r => this.$route.name === r);
    return index ? index : 0;
  }

  public get previousRoute() {
    const index = this.routes.findIndex(r => this.$route.name === r);
    return index < 0 ? undefined : this.routes[index - 1];
  }

  public get nextRoute() {
    const index = this.routes.findIndex(r => this.$route.name === r);
    return index < 0 ? undefined : this.routes[index + 1];
  }

  public get start() {
    return (
      this.edit.date.start && this.edit.date.start.toDate().toLocaleDateString()
    );
  }

  public get targets() {
    return this.edit.target.reduce<Target[]>((acc: string[], t: any) => {
      const target = t.split('-')[0];
      return acc.includes(target) ? acc : [...acc, target];
    }, []);
  }

  public async created() {
    await this.$user.ready;
    await this.watchRoute();
  }

  @Watch('$route.name')
  public async watchRoute() {
    this.initialized = false;
    await this.research.bind();

    const existing = this.research.find(this.id);
    this.edit = existing
      ? { i18n: {}, ...existing }
      : Research.empty(this.id, this.$company.id!);

    this.debouncedSave = this.id
      ? debounce(this.save.bind(this, this.id), 2000)
      : () => {};

    this.$nextTick().then(() => (this.initialized = true));
  }

  @Watch('edit', { deep: true })
  public async watchEdit(next: Research.Mutable, prev: Research.Mutable) {
    const t = next.target.reduce<Target[]>((acc: string[], t: any) => {
      const target = t.split('-')[0];
      return acc.includes(target) ? acc : [...acc, target];
    }, []);

    const multi = this.targets.length > 1;

    // Switched to multi targets
    const { introduction } = this.edit;
    const { subject: sI, message: mI } = this.edit.invitation;
    const { subject: sR, message: mR } = this.edit.reminderMail;
    if (multi && typeof mI === 'string') {
      this.edit.invitation.subject = this.forEachTarget(t, sI as string);
      this.edit.invitation.message = this.forEachTarget(t, mI as string);
      this.edit.introduction = this.forEachTarget(t, introduction as string);
      this.edit.reminderMail.subject = this.forEachTarget(t, sR as string);
      this.edit.reminderMail.message = this.forEachTarget(t, mR as string);
    }

    // Switched to single target
    if (!multi && typeof mI !== 'string') {
      type FET = ForEachTarget<string>;
      this.edit.invitation.subject = this.forOneTarget(sI as FET);
      this.edit.invitation.message = this.forOneTarget(mI as FET);
      this.edit.introduction = this.forOneTarget(introduction as FET);
      this.edit.reminderMail.subject = this.forOneTarget(sR as FET);
      this.edit.reminderMail.message = this.forOneTarget(mR as FET);
    }

    // Set default structure
    if (this.$customerNameShort === 'fb' && !this.edit.structure) {
      const module = this.module.active
        .filter(m => m.metadata.structure !== 'off-by-default')
        .sort((a, b) => a.metadata.order - b.metadata.order)
        .map(m => m.id);

      const service = this.module.services
        .filter(s => s.active && s.structure !== 'off-by-default')
        .sort((a, b) => a.order - b.order)
        .map(s => s.id);

      const fsq = module
        .map(id => this.module.active.find(m => m.id === id))
        .some(m => m && m.metadata.fsq);

      this.edit.structure = { fsq, module, service };
    }

    if (this.edit.structure) {
      const target = this.edit.structure.service
        .map(id => this.module.services.find(s => s.id === id))
        .map(s => (s ? s.category : '') as Target)
        .filter(Boolean);

      const modules = this.edit.structure.module
        .map(id => this.module.find(id)!)
        .filter(Boolean);

      this.edit.structure.fsq = modules.some(m => m.metadata.fsq);

      if (this.edit.target.join('-') != target.join('-')) {
        this.edit.target = target;

        this.edit.structure.module = this.module.active
          .sort((a, b) => a.metadata.order - b.metadata.order)
          .filter(m => {
            const targeted = target.length
              ? target.some(t => m.category.split('+').includes(t))
              : true;
            return targeted && modules.find(m2 => m2.name === m.name);
          })
          .map(m => m.id);
      }
    }

    if (this.initialized) this.debouncedSave({ ...next });
  }

  private defaultByTarget(targets: Target[], multi: boolean, value: string) {
    return multi ? this.forEachTarget(targets, value) : value;
  }

  private forCurrentTarget(
    targets: string[],
    value: string | ((target: string) => string)
  ) {
    return typeof value === 'string' ? value : value(targets[0]);
  }

  private forEachTarget(
    targets: Target[],
    value: string | ((target: Target) => string)
  ) {
    return targets.reduce((acc, target) => {
      acc[target] = typeof value === 'string' ? value : value(target);
      return acc;
    }, {} as ForEachTarget<string>);
  }

  private forOneTarget(value: ForEachTarget<string>) {
    const keys = Object.keys(value) as Target[];
    return value[keys[0]] || '';
  }

  private isEmptyTarget(value: string | ForEachTarget<string> = '') {
    return typeof value === 'string'
      ? !value
      : Object.keys(value).some(key => !value[key as Target]);
  }

  public prev() {
    if (this.previousRoute) this.$router.push({ name: this.previousRoute });
    else this.$router.replace(`/c/${this.$company.id}/research`);
  }

  public async next() {
    if (!this.nextRoute) return;

    const form = this.$el.querySelector('bce-form');
    if (!form) return this.navigateNext();

    // Input errors
    const errors = (await form.validate()) as any[];

    if (this.$route.name === 'research-respondents') {
      // Confirm dialog when forgetting the "+" button.
      const query = 'tfoot bce-input';
      const input = this.$el.querySelector(query) as HTMLBceInputElement;
      const email = typeof input.value === 'string' ? input.value.trim() : '';
      if (email) {
        const confirm = this.$bce.confirm(
          this.$t('warning'),
          this.$t('error-respondent-add', { email }),
          { cancel: this.$t('false'), ok: this.$t('true') }
        );
        if (!(await confirm)) return;
      }

      // Check existence of respondents
      if (!this.respondents.length) {
        const label = this.$t('respondents');
        const message = this.$t('error-respondent-empty');
        errors.push({
          meta: { label },
          message,
          rule: 'custom',
          name: 'custom',
        });
      }
    }

    if (!errors.length) return this.navigateNext();

    // Display a maximum of two errors
    const messages = errors.map((e: any) => {
      const { label } = e.meta;
      const message = e.message || this.$t('validation-' + e.rule);
      return `${label}:\n${message}`;
    });
    const message = messages.slice(0, 2).join('\n\n');
    this.$bce.message(messages.length > 2 ? message + '\n...' : message);
  }

  private async navigateNext() {
    const step = this.routes.findIndex(r => this.$route.name === r) + 1;
    const data = { ...this.edit, step: Math.max(this.edit.step, step) };

    await this.save(this.id, data, true);
    this.$router.push({ name: this.nextRoute });
  }

  private async save(id: string, edit: Research.Mutable, silent = false) {
    if (await this.hasErrors()) return;

    const t = this.targets;
    const multi = this.targets.length > 1;
    const byTarget = (value: ((target: string) => string) | string) =>
      multi ? this.forEachTarget(t, value) : this.forCurrentTarget(t, value);

    // Set default invitation when possible
    if (this.isEmptyTarget(edit.invitation.subject)) {
      const subject = `${company.name} - ${edit.title}`;
      edit.invitation.subject = byTarget(subject);
    }

    if (this.isEmptyTarget(edit.invitation.message)) {
      edit.invitation.message = byTarget(target => {
        const baseKey = 'invitation-text-content';
        const targetKey = `${baseKey}-${target}`;

        return this.$i18n.exists(targetKey)
          ? nl2br(this.$t(targetKey))
          : nl2br(this.$t(baseKey));
      });
    }

    // Set default introduction
    if (this.isEmptyTarget(edit.introduction)) {
      const introduction = nl2br(this.$t('introduction-text-content'));
      edit.introduction = byTarget(introduction);
    }

    // Set default reminder when possible
    if (this.isEmptyTarget(edit.reminderMail.subject)) {
      const subject = `${company.name} - ${edit.title}`;
      edit.reminderMail.subject = byTarget(subject);
    }

    if (this.isEmptyTarget(edit.reminderMail.message)) {
      edit.reminderMail.message = byTarget(target => {
        const baseKey = 'reminder-text-content';
        const targetKey = `${baseKey}-${target}`;

        return this.$i18n.exists(targetKey)
          ? nl2br(this.$t(targetKey))
          : nl2br(this.$t(baseKey));
      });
    }

    const questions = JSON.parse(JSON.stringify(edit.questions));
    const value = { ...edit, questions };
    await this.$firebase.doc(`/research/${id}`).set(value, { merge: true });

    if (!silent) this.$bce.message(this.$t('changes-saved'), 1, 'primary');
  }

  private async hasErrors() {
    const form = this.$el.querySelector('bce-form');
    const errors = form && (await form.validate(true));
    return !!errors && !!errors.length;
  }

  public async create() {
    if (!this.$user.complete) return this.account();

    const confirm = await this.$bce.confirm(
      `Weet u het zeker?`,
      ` Als u doorgaat dan wordt het onderzoek op ${this.start} uitgestuurd. `,
      { cancel: 'Nee', ok: 'Ja' }
    );

    if (confirm) {
      this.edit.status = this.isToday(this.edit.date.start)
        ? 'active'
        : 'ready';
      this.$router.push(`/c/${this.$company.id}/research`);
    }
  }

  public async account() {
    const confirm = await this.$bce.confirm(
      'Account aanmaken',
      'Om een onderzoek te starten heeft u een volledig account nodig. Wilt u deze nu aanmaken?',
      { cancel: 'Nee', ok: 'Ja' }
    );

    if (confirm) this.$router.push('/account/license');
  }

  public isToday(timestamp?: FirebaseFirestore.Timestamp | null) {
    return (
      !!timestamp &&
      timestamp.toDate().toDateString() === new Date().toDateString()
    );
  }
}
